var CordovaFacebook = (function() {
  /**
   * @param {ng.IQService} $q
   * @param {object} ezfb
   */
  function CordovaFacebook($q, ezfb) {
    /** @type {CordovaFacebook} */
    var fb;

    var grantedPermissions = [];

    this.getLoginStatus = function() {
      return ezfb.getLoginStatus();
    };

    this.login = function(permissions) {
      grantedPermissions = [];
      return ezfb.login(null, {
        auth_type: 'rerequest',
        scope: permissions.sort().join(','),
        return_scopes: true
      }).then(function(response) {
        grantedPermissions = response.authResponse.grantedScopes.split(',').sort();

        return response;
      });
    };

    this.checkHasCorrectPermissions = /** @param {string[]} permissions */ function(permissions) {
      /** @type {ng.IDeferred<boolean>} */
      var d = $q.defer(),
          missingPermissions = [];

      for (var i = 0; i < permissions.length; i++) {
        if (grantedPermissions.indexOf(permissions[i]) === -1) {
          missingPermissions.push(permissions[i]);
        }
      }

      if (missingPermissions.length > 0) {
        d.reject(missingPermissions);
      } else {
        d.resolve(true);
      }

      return d.promise;
    };

    this.logout = function() {
      return ezfb.logout();
    };

    this.api = function() {
      return $q.resolve();
    }
  }

  var app = angular.module('priorWeb');
  app.service('$cordovaFacebook', [
    '$q', 'ezfb',
    CordovaFacebook
  ]);

  return CordovaFacebook;
})();
CordovaFacebook = undefined;
