(function() {
  var app = angular.module('priorWeb');

  app.factory('EditableCompositeMenuItemViewModel', [
    'CompositeMenuItemViewModel',
    function(CompositeMenuItemViewModel) {
      function EditableCompositeMenuItemViewModel(menuItem) {
        CompositeMenuItemViewModel.apply(this, [menuItem]);

        this.getView = function() {
          return 'templates/editable-composite-menu-item.html';
        };
      }

      EditableCompositeMenuItemViewModel.prototype = Object.create(CompositeMenuItemViewModel.prototype, {
        constructor: {
          value: EditableCompositeMenuItemViewModel,
          enumerable: false
        }
      });

      EditableCompositeMenuItemViewModel.build = function(menuItem) {
        return new EditableCompositeMenuItemViewModel(menuItem);
      };

      return EditableCompositeMenuItemViewModel;
    }
  ]);
})();
