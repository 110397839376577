(function() {
  var app = angular.module('priorWeb');

  app.constant('RedsysResponseEnum', {
    CANCELATION_AUTHORIZED: '0400',
    CARD_NOT_IN_SYSTEM: '0180',
    DENIED_BY_ISSUER: '0190',
    DUPLICATED_ORDER: '0913',
    EXPIRED_CARD: '0101',
    FIRST_PAYMENT_OR_PREAUTH_AUTHORIZED: '0000',
    ISSUER_NOT_AVAILABLE2: '0912',
    ISSUER_NOT_AVAILABLE: '9912',
    LAST_PAYMENT_OR_PREAUTH_AUTHORIZED: '0099',
    NOT_AN_ACTUAL_CARD: '0125',
    OWNER_AUTENTICATION_ERROR: '0184',
    PIN_TRIED_EXCEEDED: '0106',
    REFUND_NOT_ALLOWED: '0950',
    REFUND_OR_CONFIRMATION_AUTHORIZED: '0900',
    SYSTEM_ERROR: '0909',
    TEMPORARY_FAILURE_OR_FRAUD: '0102',
    TPV_NOT_IN_FUC: '0904',
    WITHHOLDING_TEMPORARY_FAILURE_OR_FRAUD: '0202',
    WRONG_CVV2_CVC2: '0129',
    WRONG_EXPIRY_DATE: '0191',
    WRONG_SESSION: '0944'
  });
})();
