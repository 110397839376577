(function() {
  angular
    .module('uib/template/accordion/accordion.html', [])
    .run([
      '$templateCache',
      function($templateCache) {
        $templateCache.put(
          'uib/template/accordion/accordion.html',
          '<div role="tablist" ng-transclude></div>'
        );
      }
    ]);
})();
