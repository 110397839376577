/**
 * @typedef IGenericMenuItemModalControllerScope
 * @prop {() => void} cancel
 * @prop {ng.IDeferred} deferred
 * @prop {(viewItem: any) => any} getViewItem
 * @prop {(menuItem: any) => boolean} isSelected
 * @prop menuItem
 * @prop {() => void} save
 * @prop {(menuItemId: string) => void} toggleItem
 * @prop viewItem
 */

var GenericMenuItemModalController = (function() {
  var app = angular.module('priorWeb');

  /**
   * @param {IGenericMenuItemModalControllerScope & ng.IScope} $scope
   * @param {ng.translate.ITranslateService} $translate
   * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
   * @param {Alert} Alert
   */
  function GenericMenuItemModalController(
    $scope, $translate, $uibModalInstance, Alert, GenericMenuItemViewModel, MenuItemViewModel, MenuItemsRepository,
    SpecificMenuItemModel
  ) {
    var selectedItem;

    function getSpecificItem() {
      var data = selectedItem.getRawData();

      data.MenuItem.menu_item_id = data.MenuItem.id;
      data.MenuItem.id = null;
      data.MenuItem.generic_id = $scope.menuItem.getId();
      data.MenuItem.name = $scope.menuItem.getName() + ' ' + data.MenuItem.name;

      return SpecificMenuItemModel.build(data);
    }

    $scope.viewItem = GenericMenuItemViewModel.build($scope.menuItem);

    $scope.save = function() {
      if (selectedItem) {
        var item = getSpecificItem();
        $scope.deferred.resolve(item);
        $uibModalInstance.close();
      } else {
        Alert.show($translate.instant('GENERIC_MENU_ITEM_MODAL.PICK_AN_OPTION_MESSAGE'));
      }
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss();
    };

    $scope.getViewItem = function(item) {
      return MenuItemViewModel.build(item);
    };

    $scope.toggleItem = function(menuItemId) {
      selectedItem = MenuItemsRepository.get(menuItemId);
    };

    $scope.isSelected = function(menuItem) {
      return selectedItem && selectedItem.getId() === menuItem.getId();
    };

    $scope.$on(
      'modal.closing',
      /**
       * @param {ng.IAngularEvent} __event__
       * @param __resultOrReason__ - If modal is being closed the result passed to the closing method, otherwise the
       *                             reason passed to the dismiss method
       * @param {boolean} closing - True if the modal is being closed, false if it is being dismissed
       */
      function(__event__, __resultOrReason__, closing) {
        selectedItem = null;

        if (closing) {
          $scope.deferred.reject();
        }
      });
  }

  app.controller('GenericMenuItemModalController', [
    '$scope', '$translate', '$uibModalInstance', 'Alert', 'GenericMenuItemViewModel', 'MenuItemViewModel',
    'MenuItemsRepository', 'SpecificMenuItemModel',
    GenericMenuItemModalController
  ]);

  return GenericMenuItemModalController;
})();
GenericMenuItemModalController = undefined;
