var GoogleGlobalTag = (
  /**
   * @param {angular} angular
   * @param {array} dataLayer
   */
  function(angular, dataLayer) {
    /**
     * @param {ng.IDocumentService} $document
     * @param {string[]} gtagPropertyIds
     */
    function GoogleGlobalTag($document, gtagPropertyIds) {
      var firstTag = $document[0].getElementsByTagName('script')[0],
          newTag = $document[0].createElement('script');
      newTag.async = true;
      newTag.src = 'https://www.googletagmanager.com/gtag/js?id=' + gtagPropertyIds[0];
      firstTag.parentNode.insertBefore(newTag, firstTag);

      function gtag() {
        dataLayer.push(arguments);
      }

      /** @type {Gtag.Gtag} */
      this.gtag = gtag;

      gtag('js', new Date());
      gtagPropertyIds.forEach(function (propertyId) {
        gtag('config', propertyId, {
          'send_page_view': false
        });
      });
    }

    GoogleGlobalTag.prototype.setUserId = /** @param {string} userId */ function gtagSetUserId(userId) {
      this.gtag('set', { 'user_id': userId });
    };

    GoogleGlobalTag.prototype.trackConversion =
      /**
       * @param {string} propertyId
       * @param {string} transactionId
       */
      function gtagTrackConversion(propertyId, transactionId) {
        this.trackEvent('conversion', {
          'send_to': propertyId,
          'transaction_id': transactionId
        });
      };

    GoogleGlobalTag.prototype.trackEvent =
      /**
       * @param {Gtag.EventNames | string} eventName
       * @param {Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams} eventParams
       */
      function gtagTrackEvent(eventName, eventParams) {
        this.gtag('event', eventName, eventParams);
      }

    GoogleGlobalTag.prototype.trackTransaction =
      /**
       * @param {string} propertyId
       * @param {string} transactionId
       * @param {string} affiliationId
       * @param {number} value
       * @param {string} currency
       * @param {string} [shipping=0]
       * @param {string} [tax=0]
       * @param {Gtag.Item[]} [items]
       * @param {string} [coupon]
       */
      function gtagTrackTransaction(
        propertyId,
        transactionId,
        affiliationId,
        value,
        currency,
        shipping,
        tax,
        items,
        coupon
      ) {
        this.trackEvent('purchase', {
          'send_to': propertyId,
          'transaction_id': transactionId,
          'affiliation': affiliationId,
          'value': value,
          'currency': currency,
          'tax': tax || 0,
          'shipping': shipping || 0,
          'items': items,
          'coupon': coupon
        });
      };

    GoogleGlobalTag.prototype.trackView =
      /**
       * @param {string} propertyId
       * @param {string} pageName
       */
      function gtagTrackView(propertyId, pageName) {
        this.gtag('config', propertyId, {
          'page_path': '/' + pageName
        });
      };

    var app = angular.module('priorWeb');
    app.factory('GoogleGlobalTag', [
      '$document', 'gtagPropertyIds',
      /**
       * @param {ng.IDocumentService} $document
       * @param {string[]} gtagPropertyIds
       */
      function($document, gtagPropertyIds) {
        return new GoogleGlobalTag($document, gtagPropertyIds);
      }
    ]);

    return GoogleGlobalTag;
  }
)(angular, dataLayer);
GoogleGlobalTag = undefined;
