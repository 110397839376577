/**
 * @typedef IGoogleAnalyticsProvider
 * @prop {(useGtag: boolean) => void} setUseGtag
 */

/**
 * @typedef {IGoogleAnalyticsProvider & ng.IServiceProvider} GoogleAnalyticsProvider
 */

var GoogleAnalyticsWrapper = (function(angular) {
  function GoogleAnalyticsProvider() {
    var useGtag = true;

    this.setUseGtag = /** @param {boolean} newValue */ function setUseGtag(newValue) {
      useGtag = newValue;
    }

    this.$get = [
      'GoogleGlobalTag', 'GoogleTagManager',
      /**
       * @param {object} googleGlobalTag
       * @param {GoogleTagManager} googleTagManager
       */
      function GoogleAnalyticsFactory(googleGlobalTag, googleTagManager) {
        return new GoogleAnalyticsWrapper(useGtag, googleGlobalTag, googleTagManager);
      }
    ];
  }

  /**
   * @param {boolean} useGtag
   * @param {GoogleGlobalTag} googleGlobalTag
   * @param {GoogleTagManager} googleTagManager
   */
  function GoogleAnalyticsWrapper(useGtag, googleGlobalTag, googleTagManager) {
    this.useGtag = function() {
      return useGtag;
    }

    this.getGtag = function() {
      return googleGlobalTag;
    }

    this.getGtm = function() {
      return googleTagManager;
    }
  }

  GoogleAnalyticsWrapper.prototype.setUserId =
    /**
     * @param {string} newUserId
     */
    function(newUserId) {
      this.getGtag().setUserId(newUserId);
      this.getGtm().setUserId(newUserId);
    };

  GoogleAnalyticsWrapper.prototype.trackEvent =
    /**
     * @param {string} propertyId
     * @param {string} category
     * @param {string} action
     * @param {string} [label='']
     * @param {number} [value=0]
     */
    function gaTrackEvent(propertyId, category, action, label, value) {
      this.useGtag() ?
        this.getGtag().trackEvent(action, {
          event_category: category,
          event_label: label,
          send_to: propertyId,
          value: value
        }) :
        this.getGtm().trackGAEvent(propertyId, category, action, label, value);
    }

  GoogleAnalyticsWrapper.prototype.trackTransaction =
    /**
     * @param {string} propertyId
     * @param {string} transactionId
     * @param {string} affiliation
     * @param {number} value
     * @param {string} shipping
     * @param {string} tax
     * @param {string} currency
     */
    function gaTrackTransaction(propertyId, transactionId, affiliation, value, currency, tax, shipping) {
      this.useGtag() ?
        this.getGtag().trackTransaction(propertyId, transactionId, affiliation, value, currency, shipping, tax) :
        this.getGtm().trackGATransaction(propertyId, transactionId, affiliation, value, currency, shipping, tax);
    };

  GoogleAnalyticsWrapper.prototype.trackView =
    /**
     * @param {string} propertyId
     * @param {string} pageName
     */
    function gaTrackView(propertyId, pageName) {
      this.useGtag() ? this.getGtag().trackView(propertyId, pageName) : this.getGtm().trackGAView(propertyId, pageName);
    };

  var app = angular.module('priorWeb');
  app.provider('GoogleAnalytics', GoogleAnalyticsProvider);

  return GoogleAnalyticsWrapper;
})(angular);
GoogleAnalyticsWrapper = undefined;
