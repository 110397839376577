var LoginModal = (function() {
  var app = angular.module('priorWeb');

  /**
   * @param {ng.ui.bootstrap.IModalService} $uibModal
   */
  function LoginModal($uibModal) {
    this.getUibModal = function getUibModal() {
      return $uibModal;
    };
  }

  LoginModal.prototype.open = function open() {
    return this.getUibModal().open({
      animation: true,
      templateUrl: 'templates/login-enter-modal.html',
      controller: 'LoginController'
    }).result.catch(function(err) {
      console.log(err);
    });
  };

  app.factory('loginModal', [
    '$uibModal',
    /**
     * @param {ng.ui.bootstrap.IModalService} $uibModal
     */
    function($uibModal) {
      return new LoginModal($uibModal);
    }
  ]);

  return LoginModal;
}());
LoginModal = undefined;
