(function() {
  var app = angular.module('priorWeb');

  app.directive('priorDynAffix', [
    '$window',
    function($window) {
      return {
        link: function(scope, elem, attributes, __controller__) {
          function adjustWidth() {
            if (params.width) {
              elem.width(angular.element(params.width).width());
            }
          }

          function adjustAffixOffset() {
            var newBottomOffset = angular.element(params.offsetBottom).outerHeight(true),
                newTopOffset = angular.element(params.offsetTop).offset().top;

            if (elem.data('bs.affix')) {
              elem.data('bs.affix').options.offset.bottom = newBottomOffset;
              elem.data('bs.affix').options.offset.top = newTopOffset;
            }
          }

          var params = JSON.parse(attributes.priorDynAffix.replace(/'([^']+)'/g, '"$1"'));

          elem.affix({
            offset: {
              bottom: angular.element(params.offsetBottom).outerHeight(true),
              top: angular.element(params.offsetTop).offset().top
            }
          });

          angular.element($window).on('resize', function() {
            adjustAffixOffset();
            adjustWidth();
          });

          scope.$watch(function() {
            return angular.element(params.offsetBottom).outerHeight(true);
          }, adjustAffixOffset);

          scope.$watch(function() {
            return angular.element(params.offsetTop).offset().top;
          }, adjustAffixOffset);

          elem.on('affix.bs.affix', adjustWidth);
        }
      };
    }
  ]);
})();
