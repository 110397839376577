/** @typedef ISubHeaderControllerScope
 * @prop {boolean} loggedIn
 * @prop {() => void} login
 * @prop {() => void} logout
 */
var SubHeaderController = (function() {
  /**
   * @param {ng.ILocationService} $location
   * @param {ISubHeaderControllerScope & ng.IScope} $scope
   * @param {CurrentOrder} CurrentOrder
   * @param {CurrentUser} CurrentUser
   * @param {FacebookLogin} FacebookLogin
   * @param {UserResource} User
   * @param {GoogleAnalyticsWrapper} googleAnalytics
   * @param {LoginModal} loginModal
   */
  function SubHeaderController(
    $location, $scope, CurrentOrder, CurrentUser, FacebookLogin, User, googleAnalytics, loginModal
  ) {
    $scope.loggedIn = CurrentUser.isSet();

    $scope.login = function() {
      loginModal.open().finally(function() {
        $scope.loggedIn = CurrentUser.isSet();
      });
    };

    $scope.logout = function() {
      User.logout({}, function() {
        CurrentOrder.clear();
        CurrentUser.clear();
        googleAnalytics.setUserId(null);

        try {
          FacebookLogin.logout();
        } catch(e) {
          // Facebook not logged in, probably
        } finally {
          $location.url('/');
        }
      });
    };
  }

  var app = angular.module('priorWeb');
  app.controller('SubHeaderController', [
    '$location', '$scope', 'CurrentOrder', 'CurrentUser', 'FacebookLogin', 'User', 'GoogleAnalytics', 'loginModal',
    SubHeaderController
  ]);

  return SubHeaderController;
})();
SubHeaderController = undefined;
