/**
 * @typedef ItemsState
 * @property {Array<string>} customized
 * @property {Array<string>} specific
 */

/**
 * @typedef {import("redux").Reducer<ItemsState, import("redux").AnyAction>} ItemsReducer
 */
var ItemsReducerFactory = (function() {
  var app = angular.module('priorWeb');

  /**
   * @param {typeof ActionEnum} ActionEnum
   * @returns {ItemsReducer}
   */
  function ItemsReducerFactory(ActionEnum) {
    /**
     * @param {ItemsState} state
     * @param {ItemsReducerAction} action
     */
    return function itemsReducer(state, action) {
      /** @type ItemsState */
      var newState;
      if (typeof state === 'undefined') {
        newState = { customized: [], specific: [] };
      } else {
        newState = angular.copy(state);
      }

      switch (action.type) {
        case ActionEnum.REMOVE_CUSTOMIZED_ITEM:
          newState.customized = newState.customized.filter(function(itemJson) {
            return JSON.parse(itemJson).MenuItem.id !== action.itemId;
          });
          break;
        case ActionEnum.REMOVE_SPECIFIC_ITEM:
          newState.specific = newState.specific.filter(function(itemJson) {
            return JSON.parse(itemJson).MenuItem.id !== action.itemId;
          });
          break;
        case ActionEnum.SAVE_CUSTOMIZED_ITEM:
          newState.customized.push(JSON.stringify(action.item));
          break;
        case ActionEnum.SAVE_SPECIFIC_ITEM:
          newState.specific.push(JSON.stringify(action.item));
          break;
      }

      return newState;
    };
  }

  app.factory('ItemsReducer', [
    'ActionEnum',
    ItemsReducerFactory
  ]);

  return ItemsReducerFactory;
})();
ItemsReducerFactory = undefined;
