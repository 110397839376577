(function() {
  var app = angular.module('priorWeb');

  app.directive('equalTo', [
    function() {
      return {
        link: function(scope, __element__, __attributes__, controller) {
          controller.$validators.equalTo = function(modelValue, viewValue) {
            return modelValue && modelValue === scope['equalTo'];
          };
        },
        require: 'ngModel',
        scope: {
          'equalTo': '=equalTo'
        }
      };
    }
  ]);
}());
