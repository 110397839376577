var FbPixelEventEnum = (function() {
  /** @enum {string} */
  var FbPixelEventEnum = {
    ADD_PAYMENT_INFO: 'AddPaymentInfo',
    ADD_TO_CART: 'AddToCart',
    INIT_CHECKOUT: 'InitiateCheckout',
    PAGE_VIEW: 'PageView',
    PURCHASE: 'Purchase',
    VIEW_CONTENT: 'ViewContent'
  };

  var app = angular.module('priorWeb');
  app.constant('FbPixelEventEnum', FbPixelEventEnum);

  return FbPixelEventEnum;
})();
FbPixelEventEnum = undefined;
