(function() {
  /**
   * @typedef IOrderedControllerScope
   * @prop {(status: object) => Date} getAcceptedDate
   * @prop {(status: object) => string} getRejectReason
   * @prop {(order: object) => boolean} isAccepted
   * @prop {(order: object) => boolean} isCreated
   * @prop {(order: object) => boolean} isDelivery
   * @prop {(order: object) => boolean} isRejected
   * @prop {(order: object) => boolean} isUnconfirmed
   * @prop {object} order
   * @prop {string} orderId
   */

  var app = angular.module('priorWeb');
  app.controller('OrderedController', [
    '$routeParams', '$scope', '$timeout', 'Currency', 'FbPixelEventEnum', 'FbPixel',
    'OrderLocationEnum', 'OrderStatusEnum', 'RejectReasonEnum', 'RestaurantsRepository', 'Order',
    'moment', 'trackGAViews', 'trackGATransactions', 'trackGtagConversions', 'GoogleAnalytics', 'GoogleGlobalTag',
    /**
     * @param {ng.route.IRouteParamsService} $routeParams
     * @param {IOrderedControllerScope & IPriorWebRootScope & ng.IScope} $scope
     * @param {ng.ITimeoutService} $timeout
     * @param {any} moment
     * @param {string[]} trackGAViews
     * @param {string[]} trackGATransactions
     * @param {string[]} trackGtagConversions
     * @param {GoogleAnalyticsWrapper} googleAnalytics
     * @param {GoogleGlobalTag} googleGlobalTag
     */
    function(
      $routeParams, $scope, $timeout, Currency, FbPixelEventEnum, FbPixel,
      OrderLocationEnum, OrderStatusEnum, RejectReasonEnum, RestaurantsRepository, Order,
      moment, trackGAViews, trackGATransactions, trackGtagConversions, googleAnalytics, googleGlobalTag
    ) {
      var pollForResponseTimer = null,
          pollForResponseMultiplier = 1,
          restaurantId = $routeParams.restaurantId,
          restaurant;

      $scope.$on('$destroy', function() {
        clearPollForResponse();
      });

      $scope.$on('$viewContentLoaded', function(){
        trackGAViews.forEach(function(propertyId) {
          googleAnalytics.trackView(propertyId, 'ordered');
        });
      });

      $scope.orderId = $routeParams.orderId;
      $scope.order = null;

      $scope.loadedEverything.then(function() {
        restaurant = RestaurantsRepository.get(restaurantId);
        Currency.set(restaurant.getCurrency());
        pollForResponse();
      });

      function is(order, orderStatus) {
        return order && order.getStatus() === orderStatus;
      }

      $scope.isAccepted = function(order) {
        return is(order,  OrderStatusEnum.ACCEPTED);
      };

      $scope.isCreated = function(order) {
        return is(order,  OrderStatusEnum.CREATED);
      };

      $scope.isRejected = function(order) {
        return is(order,  OrderStatusEnum.REJECTED);
      };

      $scope.isUnconfirmed = function(order) {
        return is(order,  OrderStatusEnum.UNCONFIRMED);
      };

      $scope.isDelivery = function(order) {
        return parseInt(order.getWhere()) === OrderLocationEnum.DELIVERY;
      };

      $scope.getAcceptedDate = function(orderStatus) {
        var zone = moment.tz.guess();
        return moment.tz(orderStatus.getExtra(), 'UTC').tz(zone).toDate();
      }

      $scope.getRejectReason = function(orderStatus) {
        /** @type {string} */
        var reason;
        switch (orderStatus.getRejectReason()) {
          case RejectReasonEnum.BUSY:
            reason = 'ORDERED_PAGE.BUSY_REJECT_REASON';
            break;
          case RejectReasonEnum.MISSING_ITEM:
            reason = 'ORDERED_PAGE.MISSING_ITEM_REJECT_REASON';
            break;
          default:
            reason = 'ORDERED_PAGE.GENERIC_REJECT_REASON';
            break;
        }

        return reason;
      };

      function clearPollForResponse() {
        if (pollForResponseTimer) {
          $timeout.cancel(pollForResponseTimer);
          pollForResponseTimer = null;
        }
      }

      function pollForResponse() {
        var queryParams = {
          restaurantId: restaurantId,
          sortOrder: 'DESC-ASC'
        };
        Order.get({ id: $scope.orderId }, {}, function(order) {
          $scope.order = order;
          if (order.getStatus() === OrderStatusEnum.CREATED ||
              order.getStatus() === OrderStatusEnum.UNCONFIRMED) {
            scheduleNextPoll();
          }
          if (order.getStatus() === OrderStatusEnum.ACCEPTED) {
            FbPixel.track(FbPixelEventEnum.PURCHASE, {
              value: order.getTotal() / Currency.getMultiplier(),
              currency: Currency.get()
            });

            trackGATransactions.forEach(function(propertyId) {
              googleAnalytics.trackTransaction(
                propertyId,
                order.getId(),
                order.getRestaurantId(),
                order.getTotal() / Currency.getMultiplier(),
                Currency.get(),
                '0',
                '0'
              );
            });

            trackGtagConversions.forEach(function(conversionId) {
              googleGlobalTag.trackConversion(conversionId, order.getId());
            });
          }
        }, function(response) {
          if (response.status == 304) {
            scheduleNextPoll();
          }
        });
      }

      function scheduleNextPoll() {
        pollForResponseTimer = $timeout(
          pollForResponse,
          pollForResponseMultiplier * 1000
        );
        pollForResponseMultiplier = (pollForResponseMultiplier * 2) % 30;
      }
    }
  ]);
})();
