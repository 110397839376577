(function() {
  var app = angular.module('priorWeb');

  app.controller('PaymentModuleController', [
    '$rootScope', 'PaymentProviderEnum', 'RestaurantsRepository', 'State',
    /**
     * @param {ng.IRootScopeService} $rootScope
     * @param {PaymentProviderEnum} PaymentProviderEnum
     * @param {RestaurantsRepository} RestaurantsRepository
     * @param {State} State
     */
    function PaymentModuleController($rootScope, PaymentProviderEnum, RestaurantsRepository, State) {
      var ctrl = this,
          state = State.get(),
          restaurantId = state.app.restaurantId,
          restaurant;

      ctrl.paymentType = {
        value: state.currentOrder.paymentType
      };

      ctrl.updatePaymentType = function updatePaymentType() {
        ctrl.paymentTypeUpdated({ paymentType: ctrl.paymentType.value });
      };

      ctrl.isKushkiOrder = function isKushkiOrder() {
        return ctrl.paymentType.value === PaymentProviderEnum.KUSHKI;
      }

      ctrl.isMangoPayOrder = function isMangoPayOrder() {
        return ctrl.paymentType.value === PaymentProviderEnum.MANGOPAY;
      }

      ctrl.isCurrentOrderPaymentType = function isCurrentOrderPaymentType(paymentType) {
        return paymentType === ctrl.paymentType.value;
      };

      $rootScope.loadedEverything.then(function() {
        ctrl.updatePaymentType();

        restaurant = RestaurantsRepository.get(restaurantId);

        ctrl.availablePaymentProviders = restaurant.getPaymentProviders();
        ctrl.severalExistingPaymentOptions = ctrl.availablePaymentProviders.length > 1;
        ctrl.paymentSectionDisplayed = ctrl.severalExistingPaymentOptions ||
          ctrl.isMangoPayOrder() ||
          ctrl.isKushkiOrder();
      });
    }
  ]);
})();
