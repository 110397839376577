(function() {
  var app = angular.module('priorWeb');

  app.factory('GenericMenuItemViewModel', [
    'MenuItemViewModel',
    function(MenuItemViewModel) {
      function GenericMenuItemViewModel(menuItem) {
        MenuItemViewModel.apply(this, [menuItem]);

        this.getView = function() {
          return 'templates/generic-menu-item.html';
        };
      }

      GenericMenuItemViewModel.prototype = Object.create(MenuItemViewModel.prototype, {
        constructor: {
          value: GenericMenuItemViewModel,
          enumerable: false
        }
      });

      GenericMenuItemViewModel.build = function(menuItem) {
        return new GenericMenuItemViewModel(menuItem);
      };

      return GenericMenuItemViewModel;
    }
  ]);
})();
