(function() {
  var app = angular.module('priorWeb');

  app.directive('emailValidate', [
    'User',
    function(User) {
      var domains;
      return {
        link: {
          pre: function preLink(scope, elem, attrs, ctrl) {
            domains = User.emailDomains().$promise;
          },
          post: function postLink(scope, elem, attrs, ctrl) {
            scope.$watch(attrs.ngModel, function(value) {
              domains.then(function (result) {
                Mailcheck.run({
                  email: value,
                  domains: result['domains'],
                  suggested: function(suggestion) {
                      scope.$parent.suggestion = suggestion.full;
                  },
                  empty: function() {
                      scope.$parent.suggestion = null;
                  }
              });
              }).catch(function (error) {
                console.log(error);
              })
              
          });
          }
        }
      };
    }
  ]);
}());