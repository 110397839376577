(function() {
  var app = angular.module('priorWeb');

  app.factory('CustomizedCompositeMenuItemViewModel', [
    'CompositeMenuItemViewModel',
    function(CompositeMenuItemViewModel) {
      function CustomizedCompositeMenuItemViewModel(menuItem) {
        CompositeMenuItemViewModel.apply(this, [menuItem]);
      }

      CustomizedCompositeMenuItemViewModel.prototype = Object.create(CompositeMenuItemViewModel.prototype, {
        constructor: {
          value: CustomizedCompositeMenuItemViewModel,
          enumerable: false
        }
      });

      CustomizedCompositeMenuItemViewModel.build = function(menuItem) {
        return new CustomizedCompositeMenuItemViewModel(menuItem);
      };

      return CustomizedCompositeMenuItemViewModel;
    }
  ]);
})();
