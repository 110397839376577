(function() {
  var app = angular.module('priorWeb');

  /**
   * @typedef ResetPasswordModalScopeExtension
   * @property {Function} cancel
   * @property {ng.IFormController} form
   * @property {string} formClass
   * @property { { [fieldName: string]: any } } formData
   * @property {Function} resetPassword
   * @property {string} token
   */

  /**
   * @typedef {ResetPasswordModalScopeExtension & ng.IScope} ResetPasswordModalScope

   */

  app.controller('ResetPasswordModalController', [
    '$scope', '$translate', '$uibModalInstance', 'User',
    /**
     * @param {ResetPasswordModalScope} $scope
     * @param {ng.translate.ITranslateService} $translate
     * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
     */
    function ResetPasswordModalController($scope, $translate, $uibModalInstance, User) {
      $scope.formData = {
        new_password: null,
        password_confirmation: null,
        token: $scope.token
      };

      $scope.resetPassword = function resetPassword() {
        $scope.formClass = 'ng-submitted';
        if ($scope.form.$valid) {
          User.resetPassword({}, $scope.formData).$promise
            .then(
              /**
               * @param { { message: string } } response
               */
              function(response) {
                $uibModalInstance.close({
                  message: response.message
                });
              }
            )
            .catch(
              /**
               * @param {ng.IHttpResponse} response
               */
              function(response) {
                $uibModalInstance.dismiss({
                  success: false,
                  message: response.data.message
                });
              }
            );
        }
      };

      $scope.cancel = function cancel() {
        $translate('RESET_PASSWORD_MODAL.USER_CANCELED_ERROR_MESSAGE')
          .then(function(message) {
            $uibModalInstance.dismiss({
              message: message
            });
          });
      };
    }
  ]);
}());
