(function () {
  var app = angular.module('priorWeb');

  app.factory('WebOrder', [
    '$http', '$resource', 'AuthorizationPreparationModel', 'Order', 'OrderItemModel',
    'OrderItemsRepository', 'OrderModel', 'apiEndpoint',
    function(
      $http, $resource, AuthorizationPreparationModel, Order, OrderItemModel,
      OrderItemsRepository, OrderModel, apiEndpoint
    ) {
      function processAuthorizationPreparation(data, __headersGetter__, status) {
        if (status === 200 && angular.isObject(data)) {
          return AuthorizationPreparationModel.build(data);
        } else {
          return data;
        }
      }

      function buildOrder(data) {
        var order = OrderModel.build(data);
        data.OrderMenuItem.forEach(function(itemData) {
          OrderItemsRepository.save(OrderItemModel.build({
            OrderMenuItem: itemData
          }));
        });
        return order;
      }

      function processOrderData(data, __headersGetter__, status) {
        if (status === 200 && angular.isObject(data)) {
          return buildOrder(data);
        } else {
          return data;
        }
      }

      function getResponseDataProcessors(defaults, specific) {
        return (angular.isArray(defaults) ? defaults : [defaults]).concat(specific);
      }

      var TempOrder = $resource(apiEndpoint + '/orders/:id', {
        id: '@Order.id'
      }, {
        prepareAuthorization: {
          method: 'POST',
          url: apiEndpoint + '/orders/:id/prepareAuthorization',
          params: { id: '@id' },
          transformResponse: getResponseDataProcessors(
            $http.defaults.transformResponse,
            processAuthorizationPreparation
          )
        },
        getOrderDataFromMerchantData: {
          method: 'POST',
          url: apiEndpoint + '/orders/parseMerchantData',
          params: {},
          transformResponse: getResponseDataProcessors(
            $http.defaults.transformResponse,
            processOrderData
          )
        }
      });

      ['prepareAuthorization', 'getOrderDataFromMerchantData'].forEach(function(method) {
        Order[method] = TempOrder[method];
        Order.prototype['$' + method] = TempOrder['$' + method];
      });

      return Order;
    }
  ]);
})();
