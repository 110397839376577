var PriorPlacesAutocomplete = (function(angular) {
  /**
   * @param {GooglePlacesAutocomplete} GooglePlacesAutocomplete
   */
  function PriorPlacesAutocomplete(GooglePlacesAutocomplete) {
    this.autocompleteService = GooglePlacesAutocomplete;

    /** @type {JQLite} */
    this.element = null;

    /** @type {google.maps.places.Autocomplete} */
    this.autocomplete = null;
  }

  /** @type {ng.IDirectiveLinkFn} */
  PriorPlacesAutocomplete.prototype.link = function(scope, element, attributes, controller, transclude) {
    this.element = element;
    this.$scope = scope;
    this.autocomplete = this.autocompleteService.autocomplete(
      element.get()[0],
      {
        componentRestrictions: {
          country:'co'
        }
      },
      this.onResult.bind(this),
      ['formatted_address', 'address_components']
    );
  };

  PriorPlacesAutocomplete.prototype.onResult = function() {
    /**
     * @param {google.maps.GeocoderAddressComponent[]} addressComponents
     * @param {string} type
     */
    function getAddressComponent(addressComponents, type) {
      return addressComponents.reduce(function(result, component) {
        if (component.types.indexOf(type) !== -1) {
          return component.long_name;
        } else {
          return result;
        }
      }, '');
    }

    var place = this.autocomplete.getPlace();

    var excludedComponents = [
      getAddressComponent(place.address_components, 'locality'),
      getAddressComponent(place.address_components, 'country')
    ].filter(function(part) { return !!part; });


    this.element.val(place.formatted_address.split(', ').filter(function(part) {
      return !excludedComponents.includes(part);
    }).join(', '));

    this.$scope.$emit('prior.places-autocomplete-updated', place.address_components);
  };

  var app = angular.module('priorWeb');
  app.directive('priorPlacesAutocomplete', [
    'GooglePlacesAutocomplete',
    function(GooglePlacesAutocomplete) {
      return new PriorPlacesAutocomplete(GooglePlacesAutocomplete);
    }
  ]);

  return PriorPlacesAutocomplete;
})(angular);
PriorPlacesAutocomplete = undefined;
