(function() {
  var app = angular.module('priorWeb');

  app.factory('CompositeMenuItemViewModel', [
    'MenuItemViewModel',
    function(MenuItemViewModel) {
      function CompositeMenuItemViewModel(menuItem) {
        MenuItemViewModel.apply(this, [menuItem]);

        this.getView = function() {
          return 'templates/menu-item.html';
        };
      }

      CompositeMenuItemViewModel.prototype = Object.create(MenuItemViewModel.prototype, {
        constructor: {
          value: CompositeMenuItemViewModel,
          enumerable: false
        }
      });

      CompositeMenuItemViewModel.build = function(menuItem) {
        return new CompositeMenuItemViewModel(menuItem);
      };

      return CompositeMenuItemViewModel;
    }
  ]);
})();
