(function() {
  var app = angular.module('priorWeb');

  app.filter('priorCurrency', [
    'Currency',
    function(Currency) {
      return function priorCurrency(value, currency) {
        return Currency.format(value, currency);
      };
    }
  ]);
})();
