var Alert = (function() {

  var app = angular.module('priorWeb');

  /**
   * @param {ng.IRootScopeService} $rootScope
   * @param {ng.ISCEService} $sce
   * @param {ng.ui.bootstrap.IModalService} $uibModal
   */
  function Alert($rootScope, $sce, $uibModal) {
    var scope = $rootScope.$new();

    this.getScope = function getScope() {
      return scope;
    };

    this.getSceService = function getSceService() {
      return $sce;
    };

    this.getUibModal = function getUibModal() {
      return $uibModal;
    };
  }

  /**
   * @param {string} message
   * @param {string} [title="ALERT_MODAL.OK_BUTTON_LABEL"]
   * @param {string} [dismissText="ALERT_MODAL.OK_BUTTON_LABEL"]
   * @param {string} [size="sm"]
   */
  Alert.prototype.show = function showAlert(message, title, dismissText, size) {
    var modalScope = this.getScope().$new();
    size = size || 'sm';

    modalScope['alertDismisText'] = dismissText || 'ALERT_MODAL.OK_BUTTON_LABEL';
    modalScope['alertMessage'] = this.getSceService().trustAsHtml(message);
    modalScope['alertTitle'] = title || 'ALERT_MODAL.ERROR_TITLE';

    return this.getUibModal().open({
      templateUrl: 'templates/alert.html',
      size: size,
      scope: modalScope,
      backdrop: 'static'
    });
  };

  app.factory('Alert', [
    '$rootScope', '$sce', '$uibModal',
    /**
     * @param {ng.IRootScopeService} $rootScope
     * @param {ng.ISCEService} $sce
     * @param {ng.ui.bootstrap.IModalService} $uibModal
     */
    function($rootScope, $sce, $uibModal) {
      return new Alert($rootScope, $sce, $uibModal);
    }
  ]);

  return Alert;
}());
Alert = undefined;
