(function() {
  var app = angular.module('priorWeb');

  app.factory('OrderViewModel', [
    'OrderLocationEnum', 'RestaurantsRepository', 'moment',
    function(OrderLocationEnum, RestaurantsRepository, moment) {
      function OrderViewModel(order) {
        var currency = order.getCurrency(),
            id = order.getId(),
            restaurantId = order.getRestaurantId(),
            restaurantName = RestaurantsRepository.get(restaurantId).getName(),
            when = order.getWhen(),
            where = parseInt(order.getWhere()),
            total = order.getTotal();

        this.getCurrency = function() {
          return currency;
        };

        this.getId = function() {
          return id;
        };

        this.getTotal = function() {
          return total;
        };

        this.getRequestedDate = function() {
          return when == -1 ? '' : moment(when).format('dddd, D MMM YYYY');
        };

        this.getRequestedTime = function() {
          return when == -1 ? '' : moment(when).format('HH:mm');
        };

        this.getRestaurantId = function() {
          return restaurantId;
        };

        this.getRestaurantName = function() {
          return restaurantName;
        };

        this.getWhen = function() {
          return when == -1 ? 'asap' : when;
        };

        this.getWhere = function() {
          var whereText;
          switch (where) {
            case OrderLocationEnum.TAKEOUT:
              whereText = 'ORDER_STATUSES_TEMPLATE.TAKEOUT_LABEL';
              break;
            case OrderLocationEnum.DINING_IN:
              whereText = 'ORDER_STATUSES_TEMPLATE.DINING_IN_LABEL';
              break;
            case OrderLocationEnum.DELIVERY:
              whereText = 'ORDER_STATUSES_TEMPLATE.DELIVERY_LABEL';
              break;
          }

          return whereText;
        };

        this.getDeliveryInfo = function() {
          var deliveryInfo = order.getDeliveryInfo() ? order.getDeliveryInfo().getFullAddress() : '';
          return {
            hasDeliveryInfo: !!deliveryInfo,
            deliveryInfo: deliveryInfo
          };
        };
      }

      OrderViewModel.build = function(order) {
        return new OrderViewModel(order);
      };

      return OrderViewModel;
    }
  ]);
})();
