var WebsiteCurrentOrder = (function() {
  function WebsiteCurrentOrder(
    $q, ActionEnum, BaseRepository, CacheFactory, CurrentOrder, CurrentUser, InvalidOrder, MenuItemType,
    MenuItemsRepository, Order, OrderItemsRepository, OrderLocationEnum, OrderStatusEnum, State
  ) {
    var items = [],
        itemIds = {};

    CurrentOrder.apply(
      this, [
        $q, BaseRepository, CacheFactory, CurrentUser, InvalidOrder, MenuItemType, MenuItemsRepository,
        Order, OrderLocationEnum, OrderStatusEnum
      ]
    );

    this.restoreFromOrder = function restoreFromOrder(order) {
      this.setComments(order.getComments());
      this.setDeliveryAddress(order.getDeliveryInfo().getAddress());
      this.setId(order.getId());
      this.setPaymentType(order.getPaymentType());
      this.setPhone(order.getDeliveryInfo().getPhone());
      this.setPromoCode(order.getPromoCode());
      this.setRestaurantId(order.getRestaurantId());
      this.setStatus(order.getStatus());
      this.setWhen(order.getWhen());
      this.setWhere(order.getWhere());
      this.setZipCode(order.getDeliveryInfo().getZipCode());
      this.saveAll(OrderItemsRepository.getForOrderId(order.getId()));
    };

    (function(dis) {
      function wrap(fnName, beforeFn, afterFn) {
        var baseFn = dis[fnName];
        dis[fnName] = function() {
          if (beforeFn) {
            beforeFn.apply(dis, arguments);
          }

          var result = baseFn.apply(dis, arguments);

          if (afterFn) {
            result = afterFn.apply(dis, [result].concat(Array.prototype.slice.call(arguments)));
          }

          return result;
        };
      }

      function increaseOrderItemQuantityAfter() {
        var menuItemId = arguments[1],
            orderItem = dis.query({ menuItemId: menuItemId })[0],
            newQuantity = orderItem ? orderItem.getQuantity() : 0;

        State.dispatch({
          type: ActionEnum.CHANGE_ORDER_ITEM_QUANTITY,
          itemId: orderItem.getId(),
          quantity: newQuantity
        });

        return arguments[0];
      }

      function decreaseOrderItemQuantityBefore() {
        var menuItemId = arguments[1],
            orderItem = dis.query({ menuItemId: menuItemId })[0],
            quantity = orderItem ? orderItem.getQuantity() : 0;

        if (quantity === 1) {
          State.dispatch({
            type: ActionEnum.REMOVE_ORDER_ITEM,
            itemId: orderItem.getId()
          });
        }
      }

      function decreaseOrderItemQuantityAfter() {
        var menuItemId = arguments[1],
            orderItem = dis.query({ menuItemId: menuItemId })[0],
            newQuantity = orderItem ? orderItem.getQuantity() : 0;

        if (newQuantity > 0) {
          State.dispatch({
            type: ActionEnum.CHANGE_ORDER_ITEM_QUANTITY,
            itemId: orderItem.getId(),
            quantity: newQuantity
          });
        }

        return arguments[0];
      }

      wrap('setComments', undefined, function setCommentsAfter() {
        State.dispatch({
          type: ActionEnum.SET_COMMENTS,
          comments: arguments[1]
        });

        return arguments[0];
      });

      wrap('setDeliveryAddress', undefined, function setDeliveryAddressAfter() {
        State.dispatch({
          type: ActionEnum.SET_DELIVERY_ADDRESS,
          deliveryAddress: arguments[1]
        });

        return arguments[0];
      });

      wrap('setId', undefined, function setIdAfter() {
        State.dispatch({
          type: ActionEnum.SET_ORDER_ID,
          id: arguments[1]
        });

        return arguments[0];
      });

      wrap('setPaymentType', undefined, function setPaymentType() {
        State.dispatch({
          type: ActionEnum.SET_PAYMENT_TYPE,
          paymentType: arguments[1]
        });

        return arguments[0];
      });

      wrap('setPhone', undefined, function setPhoneAfter() {
        State.dispatch({
          type: ActionEnum.SET_PHONE,
          phone: arguments[1]
        });

        return arguments[0];
      });

      wrap('setPromoCode', undefined, function setPromoCodeAfter() {
        State.dispatch({
          type: ActionEnum.SET_PROMO_CODE,
          promoCode: arguments[1]
        });

        return arguments[0];
      });

      wrap('setStatus', undefined, function setStatusAfter() {
        State.dispatch({
          type: ActionEnum.SET_STATUS,
          status: arguments[1]
        });

        return arguments[0];
      });

      wrap('setWhen', undefined, function setWhenAfter() {
        State.dispatch({
          type: ActionEnum.SET_WHEN,
          when: arguments[1]
        });

        return arguments[0];
      });

      wrap('setWhere', undefined, function setWhereAfter() {
        State.dispatch({
          type: ActionEnum.SET_WHERE,
          where: arguments[1]
        });

        return arguments[0];
      });

      wrap('setZipCode', undefined, function setZipCodeAfter() {
        State.dispatch({
          type: ActionEnum.SET_ZIP_CODE,
          zipCode: arguments[1]
        });

        return arguments[0];
      });

      wrap('saveOrder', undefined, function saveOrderAfter() {
        return arguments[0];
      });

      wrap('save', undefined, function saveAfter() {
        var orderItem = arguments[1];
        State.dispatch({
          type: ActionEnum.ADD_ORDER_ITEM,
          itemId: orderItem.getId(),
          menuItemId: orderItem.getMenuItemId(),
          quantity: orderItem.getQuantity()
        });

        return arguments[0];
      });

      wrap('clear', undefined, function clearAfter() {
        State.dispatch({ type: ActionEnum.CLEAR_ORDER });

        return arguments[0];
      });

      wrap('increaseItemQuantity', undefined, increaseOrderItemQuantityAfter);
      wrap('decreaseItemQuantity', decreaseOrderItemQuantityBefore, decreaseOrderItemQuantityAfter);

      wrap('remove', function removeBefore() {
        var itemId = arguments[0],
            orderItem = dis.get(itemId);
        State.dispatch({
          type: ActionEnum.REMOVE_ORDER_ITEM,
          itemId: itemId,
          menuItemId: orderItem.getMenuItemId()
        });

        return arguments[0];
      });
    })(this);
  }

  var app = angular.module('priorCore');
  app.factory('WebsiteCurrentOrderClass', [
    'CurrentOrderClass',
    function(CurrentOrder) {
      WebsiteCurrentOrder.prototype = Object.create(CurrentOrder.prototype, {
        constructor: {
          value: WebsiteCurrentOrder,
          enumerable: false
        }
      });

      return WebsiteCurrentOrder;
    }
  ]);

  app.service('CurrentOrder', [
    '$q', 'ActionEnum', 'BaseRepositoryClass', 'CacheFactory', 'CurrentOrderClass', 'CurrentUser', 'InvalidOrder',
    'MenuItemType', 'MenuItemsRepository', 'Order', 'OrderItemsRepository', 'OrderLocationEnum', 'OrderStatusEnum',
    'State', 'WebsiteCurrentOrderClass',
    /**
     * @param {ng.IQService} $q
     * @param {typeof BaseRepository} BaseRepository
     * @param {CacheFactory} CacheFactory
     * @param {CurrentUser} CurrentUser
     * @param {typeof InvalidOrder} InvalidOrder
     * @param {typeof MenuItemType} MenuItemType
     * @param {typeof OrderLocationEnum} OrderLocationEnum
     * @param {typeof OrderStatusEnum} OrderStatusEnum
     */
    function(
      $q, ActionEnum, BaseRepository, CacheFactory, CurrentOrder, CurrentUser, InvalidOrder, MenuItemType,
      MenuItemsRepository, Order, OrderItemsRepository, OrderLocationEnum, OrderStatusEnum, State, WebsiteCurrentOrder
    ) {
      return new WebsiteCurrentOrder(
        $q, ActionEnum, BaseRepository, CacheFactory, CurrentOrder, CurrentUser, InvalidOrder, MenuItemType,
        MenuItemsRepository, Order, OrderItemsRepository, OrderLocationEnum, OrderStatusEnum, State
      );
    }
  ]);

  return WebsiteCurrentOrder;
})();
WebsiteCurrentOrder = undefined;
