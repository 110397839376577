(function() {
  var app = angular.module('priorWeb');

  app.service('OrderItemMapper', [
    'MenuItemType', 'OrderItemModel',
    function(MenuItemType, OrderItemModel) {
      function OrderItemMapper() {
        var nextId = 1;

        function getNextId() {
          return 'order_item_' + nextId++;
        }

        this.map = function mapOrderItemFromMenuItem(menuItem) {
          return OrderItemModel.build({
            OrderMenuItem: {
              id: getNextId(),
              menu_item_id: menuItem.getId(),
              name: menuItem.getName() + (menuItem.getType() === MenuItemType.CUSTOMIZED ?
                                          (' (' + menuItem.getDescription() + ')') : ''),
              price: menuItem.getPrice(),
              quantity: 1
            }
          });
        };
      }

      return new OrderItemMapper();
    }
  ]);
})();
