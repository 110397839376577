var mangoPay;
(function(mangoPay, Kushki) {
  var app = angular.module('priorWeb');

  app.controller('CardController', [
    '$scope', '$translate', 'Alert', 'Card', 'Currency', 'CurrentUser', 'FbPixelEventEnum', 'FbPixel',
    'GoogleAnalytics', 'MangopayCardRegistrationErrorEnum', 'PaymentProviderEnum', 'kushkiClientId',
    'kushkiTestEnvironment', 'trackGAViews',
    /**
     * @param {GoogleAnalyticsWrapper} googleAnalytics
     * @param {string[]} trackGAViews
     */
    function CardController(
      $scope, $translate, Alert, Card, Currency, CurrentUser, FbPixelEventEnum, FbPixel, googleAnalytics,
      MangopayCardRegistrationErrorEnum, PaymentProviderEnum, kushkiClientId, kushkiTestEnvironment, trackGAViews
    ) {
      $scope.formData = {
        name: CurrentUser.get().getName(),
        cardNumber: '',
        cardExpiration: '',
        cardCvx: ''
      };

      $scope.$on('$viewContentLoaded', function() {
        trackGAViews.forEach(function(propertyId) {
          googleAnalytics.trackView(propertyId, 'card');
        });
      });

      $scope.save = function() {
        $scope.formClass = 'ng-submitted';
        angular.element('.modal-footer button.btn-primary').addClass('disabled').prop('disabled', true);
        if ($scope.cardForm.$valid) {
          save();
        } else {
          angular.element('.modal-footer button.btn-primary').removeClass('disabled').prop('disabled', false);
        }
      };

      function save() {
        var saveCard = function(registeredCard) {
          Card.save(undefined, {
            provider_id: registeredCard.CardId,
            name: $scope.formData.name,
            number: registeredCard.CardBin || '',
            currency: Currency.get(),
            provider_type: $scope.paymentType
          }, function(savedCard) {
            var currentUser = CurrentUser.get();
            currentUser.setName($scope.formData.name),
            currentUser.setCard(savedCard);
            CurrentUser.set(currentUser);
            trackGAViews.forEach(function(propertyId) {
              googleAnalytics.trackEvent(propertyId, 'card', 'saved');
            });
            $scope.$close(savedCard);
            FbPixel.track(FbPixelEventEnum.ADD_PAYMENT_INFO);
          }, handleError);
        }

        if ($scope.paymentType === PaymentProviderEnum.KUSHKI) {
          var kushki = new Kushki({
            merchantId: kushkiClientId,
            inTestEnvironment: kushkiTestEnvironment
          });

          kushki.requestSubscriptionToken({
            card: {
              name: $scope.formData.name,
              number: $scope.formData.cardNumber,
              cvc: $scope.formData.cardCvx,
              expiryMonth: $scope.formData.cardExpiration.split('/')[0],
              expiryYear: $scope.formData.cardExpiration.split('/')[1]
            },
            currency: Currency.get()
          }, function(response) {
            if (response.error) {
              handleError(response);
            } else {
              saveCard({
                CardId: response.token,
                CardBin: $scope.formData.cardNumber.replace(/^([0-9]+)([0-9]{4})$/, 'XXXXXXXXXXXX$2')
              });
            }
          });
        } else if ($scope.paymentType === PaymentProviderEnum.MANGOPAY) {
          Card.createRegistrationData(undefined, {
            name: $scope.formData.name,
            currency: Currency.get()
          }, function(registrationData) {
            mangoPay.cardRegistration.init({
              cardRegistrationURL: registrationData.url,
              preregistrationData: registrationData.preregistrationData,
              accessKey: registrationData.accessKey,
              Id: registrationData.id
            });
            mangoPay.cardRegistration.registerCard({
              cardNumber: $scope.formData.cardNumber,
              cardExpirationDate: $scope.formData.cardExpiration.replace('/', ''),
              cardCvx: $scope.formData.cardCvx,
              cardType: 'CB_VISA_MASTERCARD'
            }, saveCard, handleError);
          }, handleError);
        }
      }

      var allowOnlyDigits = function(ev) {
        if (!ev.key.match(/Backspace|Tab|Paste|\/|[0-9]/i)) {
          ev.preventDefault();
          return false;
        }
      }

      $scope.expiryOnKeyUp = function(ev) {
        if (ev.key === '/' && $scope.formData.cardExpiration.length < 3) {
          $scope.formData.cardExpiration = '0' + $scope.formData.cardExpiration;
        } else if (ev.key === 'Backspace' && $scope.formData.cardExpiration.length === 2) {
          $scope.formData.cardExpiration = $scope.formData.cardExpiration.substr(0, 1);
        } else if ($scope.formData.cardExpiration.length === 2) {
          $scope.formData.cardExpiration = $scope.formData.cardExpiration.replace(/([0-9]{2})([0-9]*)/, '$1/$2');
        }
      }
      $scope.expiryOnKeyDown = allowOnlyDigits;
      $scope.numberOnKeyDown = allowOnlyDigits;
      $scope.cvcOnKeyDown = allowOnlyDigits;

      function handleError(error) {
        var messageId = 'CARD_PAGE.UNKNOWN_ERROR_MESSAGE';
        if (error.data) {
          error = error.data;
        }

        if (error.ResultCode) {
          switch (error.ResultCode) {
            case MangopayCardRegistrationErrorEnum.INVALID_CARD_NUMBER:
              messageId = 'CARD_PAGE.INVALID_CARD_NUMBER_ERROR_MESSAGE';
              break;
            case MangopayCardRegistrationErrorEnum.INVALID_EXPIRY_DATE:
              messageId = 'CARD_PAGE.INVALID_CARD_EXPIRY_DATE_ERROR_MESSAGE';
              break;
            case MangopayCardRegistrationErrorEnum.INVALID_CVV:
              messageId = 'CARD_PAGE.INVALID_CARD_CVV_ERROR_MESSAGE';
              break;
            case MangopayCardRegistrationErrorEnum.WRONG_HOLDER_NAME:
              messageId = 'CARD_PAGE.INVALID_CARD_HOLDER_NAME_ERROR_MESSAGE';
              break;
          }
        }

        Alert.show($translate.instant(messageId)).result.finally(function() {
          angular.element('.modal-footer button.btn-primary').removeClass('disabled').prop('disabled', false);
        });
      }
    }
  ]);
})(mangoPay, Kushki);
