(function() {
  var app = angular.module('priorWeb');

  /**
   * @typedef IReviewOrderModalControllerScope
   * @property orderReviewView
   */

  app.controller('ReviewOrderModalController', [
    '$scope', 'OrderReviewViewModel',
    /**
     * @param {IReviewOrderModalControllerScope & ng.IScope} $scope
     * @param OrderReviewViewModel
     */
    function ($scope, OrderReviewViewModel) {
      $scope.orderReviewView = OrderReviewViewModel.build();
    }
  ]);
}());
