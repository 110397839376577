/**
 * @typedef ICompositeMenuItemModalControllerScope
 * @prop {() => void} cancel
 * @prop {ng.IDeferred} deferred
 * @prop {(viewItem: any) => any} getViewItem
 * @prop {(menuItem: any) => boolean} isSelected
 * @prop menuItem
 * @prop {() => void} save
 * @prop {(menuItemId: string) => void} toggleItem
 * @prop viewItem
 */

var CompositeMenuItemModalController = (function() {
  var app = angular.module('priorWeb');
  /**
   * @param {ICompositeMenuItemModalControllerScope & ng.IScope} $scope
   * @param {ng.translate.ITranslateService} $translate
   * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
   * @param {Alert} Alert
   */
  function CompositeMenuItemModalController(
    $scope, $translate, $uibModalInstance, Alert, CompositeMenuItemViewModel, CustomizedCompositeMenuItemModel,
    ModalMenuItemsRepository, MenuItemViewModel, MenuItemsRepository
  ) {
    function getCustomizedItem() {
      if ($scope.menuItem.isSelectionValid(ModalMenuItemsRepository.getAll())) {
        var selectedItems = ModalMenuItemsRepository.getAll(),
            data = $scope.menuItem.getRawData();

        data.MenuItem.editable = false;
        data.MenuItem.standalone = false;
        data.MenuItem.menu_item_id = data.MenuItem.id;
        data.MenuItem.id = null;
        data.ChildMenuItem = selectedItems.map(function(selectedItem) {
          return { id: selectedItem.getId() };
        });

        return CustomizedCompositeMenuItemModel.build(data);
      } else {
        return false;
      }
    }

    $scope.viewItem = CompositeMenuItemViewModel.build($scope.menuItem);

    $scope.save = function() {
      var item = getCustomizedItem();
      if (item !== false) {
        $scope.deferred.resolve(item);
        $uibModalInstance.close();
      } else {
        var invalidRuleSets = $scope.menuItem.getInvalidRules(ModalMenuItemsRepository.getAll());
        Alert.show(
          $translate.instant('COMPOSITE_MENU_ITEM_MODAL.PICK_LABEL') + ': <br />' +
            invalidRuleSets.map(function(invalidRuleSet) {
              return '<ul>' + invalidRuleSet.rules.map(function(rule) {
                var ruleMessage = '';
                if (rule.minCount >= 0 && rule.maxCount > 0) {
                  ruleMessage =
                    '<li>' +
                    $translate.instant('COMPOSITE_MENU_ITEM_MODAL.COMPOSITE_RULE_ERROR_MESSAGE', {
                      exactRule: rule.minCount === rule.maxCount,
                      minCount: rule.minCount,
                      maxCount: rule.maxCount,
                      sectionName: rule.sectionName
                    }, 'messageformat') +
                    '</li>';
                }
                return ruleMessage;
              }).filter(/** @param {string} message */ function(message) { return message; }).join('') + '</ul>';
            }).join($translate.instant('COMPOSITE_MENU_ITEM_MODAL.OR_LABEL') + '<br />')
        );
      }
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss();
    };

    $scope.getViewItem = function(item) {
      return MenuItemViewModel.build(item);
    };

    $scope.toggleItem = function(menuItemId) {
      if (ModalMenuItemsRepository.has(menuItemId)) {
        ModalMenuItemsRepository.remove(menuItemId);
      } else {
        ModalMenuItemsRepository.save(MenuItemsRepository.get(menuItemId));
      }
    };

    $scope.isSelected = function(menuItem) {
      return ModalMenuItemsRepository.has(menuItem.getId());
    };

    $scope.$on(
      'modal.closing',
      /**
       * @param {ng.IAngularEvent} __event__
       * @param __resultOrReason__ - If modal is being closed the result passed to the closing method, otherwise the
       *                             reason passed to the dismiss method
       * @param {boolean} closing - True if the modal is being closed, false if it is being dismissed
       */
      function(__event__, __resultOrReason__, closing) {
        ModalMenuItemsRepository.removeAll();

        if (!closing) {
          $scope.deferred.reject();
        }
      }
    );
  }

  app.controller('CompositeMenuItemModalController', [
    '$scope', '$translate', '$uibModalInstance', 'Alert', 'CompositeMenuItemViewModel',
    'CustomizedCompositeMenuItemModel', 'ModalMenuItemsRepository', 'MenuItemViewModel', 'MenuItemsRepository',
    CompositeMenuItemModalController
  ]);

  return CompositeMenuItemModalController;
})();
CompositeMenuItemModalController = undefined;
