/**
 * @typedef IMenuControllerScope
 * @prop {() => boolean} allowOrder
 * @prop {array} categories
 * @prop {(event: Event, viewItem: any) => void} customizeItem
 * @prop {(event: Event, viewItem: any) => void} decreaseItemQuantity
 * @prop {(category: any) => any} getCategoryView
 * @prop {(item: any) => any} getViewItem
 * @prop {boolean} hasMenuImages
 * @prop {(event: Event, viewItem: any) => void} increaseItemQuantity
 * @prop {{[key: string]: any}} items
 * @prop {number} navbarOrderDiscount
 * @prop {string} nextStartTime
 * @prop {number} orderTotal
 * @prop {() => void} processOrder
 * @prop {(event: Event, viewItem: any, customizedViewItem: any) => void} removeCustomizedItem
 * @prop {(event: Event, viewItem: any, specificViewItem: any) => void} removeSpecificItem
 * @prop {string} restaurantDeliveryCharge
 * @prop {string} restaurantOpeningHours
 * @prop restaurantView
 * @prop {(event: Event, viewItem: any) => void} specifyItem
 * @prop {(categoryId: string) => void} scrollTo
 * @prop {(event: Event, viewItem: any) => void} toggleItem
 */

var MenuController = (function() {
  /**
   * @param {ng.IAnchorScrollService} $anchorScroll
   * @param {ng.ILocationService} $location
   * @param {IPriorWebRootScope & ng.IRootScopeService} $rootScope
   * @param {ng.route.IRouteParamsService} $routeParams
   * @param {ng.IQService} $q
   * @param {IMenuControllerScope & IPriorWebRootScope & ng.IScope} $scope
   * @param {ng.ITimeoutService} $timeout
   * @param {ng.translate.ITranslateService} $translate
   * @param {ng.ui.bootstrap.IModalService} $uibModal
   * @param {typeof ActionEnum} ActionEnum
   * @param {Alert} Alert
   * @param {CurrentOrder & BaseRepository} CurrentOrder
   * @param {CurrentUser} CurrentUser
   * @param {typeof FbPixelEventEnum} FbPixelEventEnum
   * @param {State} State
   * @param {string[]} trackGAViews
   * @param {GoogleAnalyticsWrapper} googleAnalytics
   * @param {LoginModal} loginModal
   */
  function MenuController (
    $anchorScroll, $location, $rootScope, $routeParams, $q, $scope, $timeout, $translate, $uibModal, ActionEnum,
    Alert, Category, CategoryViewModel, CategoriesRepository, Currency, CurrentOrder, CurrentUser,
    CompositeMenuItemViewModel, EditableCompositeMenuItemViewModel, FbPixelEventEnum, FbPixel, GenericMenuItemViewModel,
    MenuItem, MenuItemType, MenuItemsRepository, NonStandaloneMenuItemViewModel, OrderItemMapper, OrderStatusEnum,
    RestaurantsRepository, RestaurantViewModel, RuntimeError, SpecificMenuItemViewModel, StandaloneMenuItemViewModel,
    State, trackGAViews, googleAnalytics, loginModal
  ) {
    var menuItemId = $routeParams.menuItemId,
        resetPasswordToken = $routeParams.resetPasswordToken,
        restaurantId = $routeParams.restaurantId,
        restaurant;

    $scope.categories = [];
    $scope.items = {};

    $scope.$on('$viewContentLoaded', function() {
      trackGAViews.forEach(function(propertyId) {
        googleAnalytics.trackView(propertyId, 'menu');
      });
      FbPixel.track('ViewContent');
    });

    $scope.loadedRestaurants.then(function() {
      restaurant = RestaurantsRepository.get(restaurantId);
      Currency.set(restaurant.getCurrency());
      State.dispatch({ type: ActionEnum.SET_RESTAURANT_ID, restaurantId: restaurantId });

      if (!restaurantId || !restaurant || !restaurant.isActive()) {
        $location.url('/');
        return;
      }

      $scope.restaurantDeliveryCharge = restaurant.getDeliveryCharge();
      $scope.nextStartTime = restaurant.getNextStartTime();
      $scope.restaurantView = RestaurantViewModel.build(restaurant);
      $scope.restaurantOpeningHours = $scope.restaurantView.getOpeningHours() ||
        $translate.instant('NAVBAR.RESTAURANT_CLOSED_LABEL');
      $scope.hasMenuImages = restaurant.hasMenuImages();
    });

    function allowOrder() {
      return restaurant && restaurant.allowOrder();
    }

    function refreshMenu() {
      var categoriesDeferred = $q.defer(),
          menuItemsDeferred = $q.defer(),
          promises = [categoriesDeferred.promise, menuItemsDeferred.promise];
      Category.query({
        restaurantId: restaurantId
      }).$promise
        .then(function(categories) {
          CategoriesRepository.query({
            restaurantId: restaurantId
          }).forEach(function(category) {
            CategoriesRepository.remove(category.getId());
          });
          CategoriesRepository.saveAll(categories);
          $scope.categories = categories;
          categoriesDeferred.resolve();
        })
        .catch(function(err) {
          if (err.status !== 304) {
            console.log(err);
          }
        });
      MenuItem.query({
        restaurantId: restaurantId
      }).$promise
        .then(function(menuItems) {
          MenuItemsRepository.query({
            restaurantId: restaurantId
          }).forEach(function(menuItem) {
            if (menuItem.getType() !== MenuItemType.CUSTOMIZED &&
                menuItem.getType() !== MenuItemType.SPECIFIC) {
              MenuItemsRepository.remove(menuItem.getId());
            }
          });
          MenuItemsRepository.saveAll(menuItems);
          menuItemsDeferred.resolve();
        })
        .catch(function(err) {
          if (err.status !== 304) {
            console.log(err);
          }
        });

      $q.all(promises).then(function() {
        displayMenu();
      });
    }

    function customizeItem(menuItem) {
      /** @type {ICompositeMenuItemModalControllerScope & ng.IScope} */
      var modalScope = ($rootScope.$new());
      modalScope.menuItem = menuItem;
      modalScope.deferred = $q.defer();

      $uibModal.open({
        animation: true,
        templateUrl: 'templates/editable-composite-menu-item-modal.html',
        controller: 'CompositeMenuItemModalController',
        scope: modalScope
      });

      return modalScope.deferred.promise;
    }

    function displayMenu() {
      $scope.items = {
        0: getItems(0)
      };
      $scope.categories = CategoriesRepository.getForRestaurant(restaurantId);
      $scope.categories.forEach(function(category) {
        var categoryId = category.getId();
        $scope.items[categoryId] = getItems(categoryId);
      });
    }

    function login() {
      var promise;
      if (!CurrentUser.isSet()) {
        promise = loginModal.open();
      } else {
        promise = $q.resolve();
      }

      return promise;
    }

    function getItems(categoryId) {
      return MenuItemsRepository.getStandaloneForRestaurantAndCategory(restaurantId, categoryId);
    }

    function saveOrderItem(menuItem) {
      var orderItem = OrderItemMapper.map(menuItem);
      CurrentOrder.save(orderItem);
      FbPixel.track(FbPixelEventEnum.ADD_TO_CART);
      updateOrderTotal();
    }

    function specifyItem(menuItem) {
      /** @type {{menuItem: any, deferred: ng.IDeferred} & ng.IScope} */
      var modalScope = ($rootScope.$new());
      modalScope.menuItem = menuItem;
      modalScope.deferred = $q.defer();
      $uibModal.open({
        animation: true,
        templateUrl: 'templates/generic-menu-item-modal.html',
        controller: 'GenericMenuItemModalController',
        scope: modalScope,
      });

      return modalScope.deferred.promise;
    }

    function updateOrderTotal() {
      var updateValues = function() {
        $scope.orderTotal = CurrentOrder.getTotal() - CurrentOrder.getDeliveryCharge().value;
        $scope.navbarOrderDiscount = CurrentOrder.getDiscount() + CurrentOrder.getItemsDiscount();
      };
      if (CurrentUser.isSet()) {
        CurrentOrder.applyPromos().finally(updateValues);
      } else {
        updateValues.call(this);
      }
    }

    $q.all([$rootScope.loadedCategories, $rootScope.loadedItems]).then(function() {
      displayMenu();
      refreshMenu();
      updateOrderTotal();

      if (resetPasswordToken) {
        /** @type {{ token: string} & ng.IScope} */
        var modalScope = ($rootScope.$new());
        modalScope.token = resetPasswordToken;
        $uibModal.open({
          animation: true,
          controller: 'ResetPasswordModalController',
          keyboard: false,
          scope: modalScope,
          templateUrl: 'templates/reset-password-modal.html'
        }).result
          .then(
            /**
             * @param { { message: string } } result
             */
            function(result) {
              Alert.show(result.message).closed.then(function() {
                login().then(function() {
                  $location.url('/menu/' + restaurantId);
                });
              });
            }
          )
          .catch(
            /**
             * @param { { message: string } } result
             */
            function(result) {
              Alert.show(result.message);
              $location.url('/menu/' + restaurantId);
            }
          );
      }
    });

    $scope.allowOrder = allowOrder;

    $scope.loadedEverything.then(function() {
      if (!CurrentOrder.hasItems() || CurrentOrder.getRestaurantId() !== restaurant.getId() ||
          CurrentOrder.getStatus() != OrderStatusEnum.CREATED) {
        CurrentOrder.init(restaurant);
      }

      if (CurrentOrder.getPaymentType() === null) {
        State.dispatch({ type: ActionEnum.SET_PAYMENT_TYPE, paymentType: restaurant.getDefaultPaymentType() });
      }

      $scope.customizeItem = function(event, viewItem) {
        event.preventDefault();
        event.stopPropagation();

        var parentItem = MenuItemsRepository.get(viewItem.getId());
        customizeItem(parentItem).then(function(menuItem) {
          viewItem.setIsSelected(true);
          var prevQuantity = 0,
              alreadySelectedItem = MenuItemsRepository.getCustomizedFor(parentItem.getId())
              .reduce(
                function(selectedItem, item) {
                  if (menuItem.getDescription() === item.getDescription()) {
                    selectedItem = item;
                  }

                  return selectedItem;
                },
                null
              );

          if (alreadySelectedItem) {
            prevQuantity = CurrentOrder.getItemQuantity(alreadySelectedItem.getId());
            var orderItem = CurrentOrder.query({ menuItemId: alreadySelectedItem.getId() })[0];
            State.dispatch({
              type: ActionEnum.REMOVE_SPECIFIC_ITEM,
              itemId: alreadySelectedItem.getId()
            });
            CurrentOrder.remove(orderItem.getId());
            MenuItemsRepository.remove(alreadySelectedItem.getId());
          }

          MenuItemsRepository.save(menuItem);
          saveOrderItem(menuItem);
          State.dispatch({
            type: ActionEnum.SAVE_CUSTOMIZED_ITEM,
            item: menuItem.getRawData()
          });

          for (var i = 0; i < prevQuantity; i++) {
            CurrentOrder.increaseItemQuantity(menuItem.getId());
          }
        });
      };

      $scope.decreaseItemQuantity = function(event, viewItem) {
        event.preventDefault();
        event.stopPropagation();

        var menuItemId = viewItem.getId(),
            newQuantity;
        CurrentOrder.decreaseItemQuantity(menuItemId);
        newQuantity = CurrentOrder.getItemQuantity(menuItemId);
        if (newQuantity > 0) {
          viewItem.setQuantity(newQuantity);
        } else {
          viewItem.setIsSelected(false);
        }
        updateOrderTotal();
      };

      $scope.getCategoryView = function(category) {
        return CategoryViewModel.build(category);
      };

      $scope.getViewItem = function(item) {
        var viewItem;
        var quantity;

        switch (item.getType()) {
        case MenuItemType.COMPOSITE:
        case MenuItemType.CUSTOMIZED:
          if (item.isEditable()) {
            viewItem = EditableCompositeMenuItemViewModel.build(item);
            quantity = item.getCustomizedItems().length;
          } else {
            viewItem = CompositeMenuItemViewModel.build(item);
            quantity = CurrentOrder.getItemQuantity(item.getId());
          }
          break;
        case MenuItemType.GENERIC:
          viewItem = GenericMenuItemViewModel.build(item);
          quantity = item.getSpecificItems().reduce(function(quantity, specificItem) {
            return quantity + CurrentOrder.getItemQuantity(specificItem.getId());
          }, 0);
          break;
        case MenuItemType.STANDALONE:
          viewItem = StandaloneMenuItemViewModel.build(item);
          quantity = CurrentOrder.getItemQuantity(item.getId());
          break;
        case MenuItemType.NON_STANDALONE:
          viewItem = NonStandaloneMenuItemViewModel.build(item);
          quantity = CurrentOrder.getItemQuantity(item.getId());
          break;
        case MenuItemType.SPECIFIC:
          viewItem = SpecificMenuItemViewModel.build(item);
          quantity = CurrentOrder.getItemQuantity(item.getId());
          break;
        default:
          throw RuntimeError.build({
            message: 'Unknown or unsupported menu item type "' + item.getType() + '"'
          });
        }
        viewItem.setQuantity(quantity);
        viewItem.setIsSelected(quantity > 0);

        return viewItem;
      };

      $scope.increaseItemQuantity = function(event, viewItem) {
        event.preventDefault();
        event.stopPropagation();

        var menuItemId = viewItem.getId();
        CurrentOrder.increaseItemQuantity(menuItemId);
        var newQuantity = CurrentOrder.getItemQuantity(menuItemId);
        viewItem.setQuantity(newQuantity);
        updateOrderTotal();
      };

      $scope.processOrder = function() {
        if (allowOrder()) {
          if (CurrentOrder.hasItems()) {
            login().then(function() {
              if (CurrentUser.isSet()) {
                $location.url('/payment');
              }
            });
          } else {
            Alert.show('Elige por lo menos un item del menu');
          }
        }
      };

      $scope.removeCustomizedItem = function(event, viewItem, customizedViewItem) {
        event.preventDefault();
        event.stopPropagation();

        var customizedItem = MenuItemsRepository.get(customizedViewItem.getId()),
            parentItem = MenuItemsRepository.get(viewItem.getId()),
            currentQuantity = CurrentOrder.getItemQuantity(customizedItem.getId());

        if (currentQuantity === 1) {
          var orderItem = CurrentOrder.query({ menuItemId: customizedItem.getId() })[0];
          CurrentOrder.remove(orderItem.getId());
          State.dispatch({
            type: ActionEnum.REMOVE_CUSTOMIZED_ITEM,
            itemId: customizedItem.getId()
          });
          MenuItemsRepository.remove(customizedItem.getId());
        } else {
          CurrentOrder.decreaseItemQuantity(customizedItem.getId());
          customizedViewItem.setQuantity(currentQuantity - 1);
        }

        if (parentItem.getCustomizedItems().length === 0) {
          viewItem.setIsSelected(false);
          viewItem.setQuantity(0);
        }
        updateOrderTotal();
      };

      $scope.removeSpecificItem = function(event, viewItem, specificViewItem) {
        event.preventDefault();
        event.stopPropagation();

        var specificItem = MenuItemsRepository.get(specificViewItem.getId()),
            parentItem = MenuItemsRepository.get(viewItem.getId()),
            currentQuantity = CurrentOrder.getItemQuantity(specificItem.getId());
        if (currentQuantity === 1) {
          var orderItem = CurrentOrder.query({ menuItemId: specificItem.getId() })[0];
          CurrentOrder.remove(orderItem.getId());
          State.dispatch({
            type: ActionEnum.REMOVE_SPECIFIC_ITEM,
            itemId: specificItem.getId()
          });
          MenuItemsRepository.remove(specificItem.getId());
        } else {
          CurrentOrder.decreaseItemQuantity(specificItem.getId());
          specificViewItem.setQuantity(currentQuantity - 1);
        }
        if (parentItem.getSpecificItems().length === 0) {
          viewItem.setIsSelected(false);
          viewItem.setQuantity(0);
        }
        updateOrderTotal();
      };

      $scope.scrollTo = function(categoryId) {
        $anchorScroll('category-' + categoryId);
      };

      $scope.specifyItem = function(event, viewItem) {
        event.preventDefault();
        event.stopPropagation();

        var parentItem = MenuItemsRepository.get(viewItem.getId());
        specifyItem(parentItem).then(function(menuItem) {
          var alreadySelectedItem =
              parentItem.getSpecificItems().reduce(function(alreadySelectedItem, specific) {
                if (!alreadySelectedItem && specific.getSelectionId() == menuItem.getSelectionId()) {
                  alreadySelectedItem = specific;
                }

                return alreadySelectedItem;
              }, null),
              prevQuantity = 0;

          // TODO: Make this less hacky
          if (alreadySelectedItem) {
            prevQuantity = CurrentOrder.getItemQuantity(alreadySelectedItem.getId());
            var orderItem = CurrentOrder.query({ menuItemId: alreadySelectedItem.getId() })[0];
            State.dispatch({
              type: ActionEnum.REMOVE_SPECIFIC_ITEM,
              itemId: alreadySelectedItem.getId()
            });
            CurrentOrder.remove(orderItem.getId());
            MenuItemsRepository.remove(alreadySelectedItem.getId());
          }
          MenuItemsRepository.save(menuItem);
          viewItem.setIsSelected(true);
          State.dispatch({
            type: ActionEnum.SAVE_SPECIFIC_ITEM,
            item: menuItem.getRawData()
          });
          saveOrderItem(menuItem);
          for (var i = 0; i < prevQuantity; i++) {
            CurrentOrder.increaseItemQuantity(menuItem.getId());
          }
        });
      };

      $scope.toggleItem = function(event, viewItem) {
        event.preventDefault();
        event.stopPropagation();

        var menuItemId = viewItem.getId();
        if (CurrentOrder.has(menuItemId)) {
          var orderItem = CurrentOrder.query({ menuItemId: menuItemId })[0];
          CurrentOrder.remove(orderItem.getId());
          viewItem.setIsSelected(false);
        } else {
          var menuItem = MenuItemsRepository.get(menuItemId);
          saveOrderItem(menuItem);
          viewItem.setIsSelected(true);
          viewItem.setQuantity(1);
        }
        updateOrderTotal();
      };

      $timeout(function() {
        if (menuItemId) {
          $anchorScroll('menuItem-' + menuItemId);
        }
      }, 1000);
    });
  }

  var app = angular.module('priorWeb');
  app.controller('MenuController', [
    '$anchorScroll', '$location', '$rootScope', '$routeParams', '$q', '$scope', '$timeout', '$translate', '$uibModal',
    'ActionEnum', 'Alert', 'Category', 'CategoryViewModel', 'CategoriesRepository', 'Currency', 'CurrentOrder',
    'CurrentUser', 'CompositeMenuItemViewModel', 'EditableCompositeMenuItemViewModel', 'FbPixelEventEnum', 'FbPixel',
    'GenericMenuItemViewModel', 'MenuItem', 'MenuItemType', 'MenuItemsRepository', 'NonStandaloneMenuItemViewModel',
    'OrderItemMapper', 'OrderStatusEnum', 'RestaurantsRepository', 'RestaurantViewModel', 'RuntimeError',
    'SpecificMenuItemViewModel', 'StandaloneMenuItemViewModel', 'State', 'trackGAViews', 'GoogleAnalytics',
    'loginModal',
    MenuController
  ]);

  return MenuController;
})();
MenuController = undefined;
