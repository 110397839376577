(function() {
  var app = angular.module('priorWeb');

  app.factory('MenuItemViewModel', [
    'NotImplemented', 'apiEndpoint',
    function(NotImplemented, apiEndpoint) {
      function MenuItemViewModel(menuItem) {
        var id = menuItem.getId(),
            image = menuItem.getImage(),
            name = menuItem.getName(),
            description = menuItem.getDescription(),
            price = menuItem.getPrice(),
            selected = false,
            quantity = 0;

        function isSelected() {
          return selected;
        }
        this.isSelected = isSelected;

        function setIsSelected(newValue) {
          selected = newValue;
        }
        this.setIsSelected = setIsSelected;

        this.getId = function() {
          return id;
        };

        this.getImageName = function getImageName() {
          var img = image ? image.getName() : name;

          return img;
        };

        this.getImagePath = function getImagePath() {
          var img = image ? image.getPath() : undefined;

          return img;
        };

        this.getName = function() {
          return name;
        };

        this.getDescription = function() {
          return description;
        };

        this.getPrice = function() {
          return price;
        };

        this.getTotal = function() {
          return quantity * price;
        };

        this.getQuantity = function() {
          return quantity;
        };

        this.setQuantity = function(newQuantity) {
          quantity = newQuantity;
        };

        this.getView = function() {
          throw NotImplemented.build({message: 'Need to implement getView'});
        };
      }

      MenuItemViewModel.build = function(menuItem) {
        return new MenuItemViewModel(menuItem);
      };

      return MenuItemViewModel;
    }
  ]);
})();
