/**
 * @typedef AppState
 * @property {string} restaurantId
 */

/**
 * @typedef {import("redux").Reducer<AppState, import("redux").AnyAction>} AppReducer
 */

var AppReducerFactory = (function() {

  var app = angular.module('priorWeb');

  /**
   * @param {typeof ActionEnum} ActionEnum
   * @returns {AppReducer}
   */
  function AppReducerFactory(ActionEnum) {
    return function appReducer(state, action) {
      /** @type AppState */
      var newState;
      if (typeof state === 'undefined') {
        newState = { restaurantId: null };
      } else {
        newState = angular.copy(state);
      }

      switch (action.type) {
        case ActionEnum.CLEAR_RESTAURANT_ID:
          newState.restaurantId = null;
          break;
        case ActionEnum.SET_RESTAURANT_ID:
          newState.restaurantId = action.restaurantId;
          break;
      }

      return newState;
    };
  }

  app.factory('AppReducer', [
    'ActionEnum',
    AppReducerFactory
  ]);

  return AppReducerFactory;
})();
AppReducerFactory = undefined;

