/**
 * @typedef CurrentOrderState
 * @property {string} [city]
 * @property {string} [comments]
 * @property {string} [deliveryAddress]
 * @property {string} [id]
 * @property {array} items
 * @property {string} paymentType
 * @property {string} [pendingPreAuthId]
 * @property {string} [phone]
 * @property {string} [promoCode]
 * @property {string} status
 * @property {number} when
 * @property {number} where
 * @property {string} [zipCode]
 */

/**
 * @typedef {import("redux").Reducer<CurrentOrderState, import("redux").AnyAction>} CurrentOrderReducer
 */

var CurrentOrderReducerFactory = (function() {
  var app = angular.module('priorWeb');

  /**
   * @param {typeof ActionEnum} ActionEnum
   * @return {CurrentOrderReducer}
   */
  function CurrentOrderReducerFactory(ActionEnum) {
    /** @type CurrentOrderState */
    var DEFAULT_STATE = { items: [], paymentType: null, status: 'created', where: 3, when: -1 };

    return function currentOrderReducer(state, action) {
      var newState;
      if (typeof state === 'undefined') {
        newState = DEFAULT_STATE;
      } else {
        newState = angular.copy(state);
      }

      switch (action.type) {
      case ActionEnum.ADD_ORDER_ITEM:
        newState.items = newState.items.filter(function(item) {
          return item.id !== action.itemId;
        });
        newState.items.push({
          id: action.itemId,
          menuItemId: action.menuItemId,
          quantity: action.quantity
        });
        break;
      case ActionEnum.CHANGE_ORDER_ITEM_QUANTITY:
        newState.items = newState.items.map(function(item) {
          if (action.itemId === item.id) {
            return { id: item.id, menuItemId: item.menuItemId, quantity: action.quantity };
          } else {
            return item;
          }
        });
        break;
      case ActionEnum.CLEAR_ORDER:
        newState = DEFAULT_STATE;
        break;
      case ActionEnum.CLEAR_3DS_AUTHORIZATION:
        newState.pendingPreAuthId = undefined;
        break;
      case ActionEnum.REQUEST_3DS_AUTHORIZATION:
        newState.pendingPreAuthId = action.preAuthId;
        break;
      case ActionEnum.REMOVE_ORDER_ITEM:
        newState.items = newState.items.filter(function(item) {
          return action.itemId !== item.id;
        });
        break;
      case ActionEnum.SET_COMMENTS:
        newState.comments = action.comments;
        break;
      case ActionEnum.SET_DELIVERY_ADDRESS:
        newState.deliveryAddress = action.deliveryAddress;
        break;
      case ActionEnum.SET_ORDER_ID:
        newState.id = action.id;
        break;
      case ActionEnum.SET_PAYMENT_TYPE:
        newState.paymentType = action.paymentType;
        break;
      case ActionEnum.SET_PHONE:
        newState.phone = action.phone;
        break;
      case ActionEnum.SET_PROMO_CODE:
        newState.promoCode = action.promoCode;
        break;
      case ActionEnum.SET_STATUS:
        newState.status = action.status;
      case ActionEnum.SET_WHEN:
        newState.when = action.when;
        break;
      case ActionEnum.SET_WHERE:
        newState.where = action.where;
        break;
      case ActionEnum.SET_ZIP_CODE:
        newState.zipCode = action.zipCode;
        break;
      }

      return newState;
    };
  }

  app.factory('CurrentOrderReducer', [
    'ActionEnum',
    CurrentOrderReducerFactory
  ]);

  return CurrentOrderReducerFactory;
})();
CurrentOrderReducerFactory = undefined;
