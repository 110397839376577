var GooglePlacesAutocomplete = (function(angular, google) {
  function GooglePlacesAutocomplete() {
    if (!google.maps.places.Autocomplete) {
      throw new Error('Could not find Google Places Autocomplete library');
    }
  }

  GooglePlacesAutocomplete.prototype.autocomplete = function GPAAutocomplete(input, options, callback, fields) {
    var autocomplete = new google.maps.places.Autocomplete(input, options);

    if (fields) {
      autocomplete.setFields(fields);
    }

    autocomplete.addListener('place_changed', callback);

    return autocomplete;
  };

  var app = angular.module('priorWeb');
  app.service('GooglePlacesAutocomplete', [
    function() {
      return new GooglePlacesAutocomplete()
    }
  ]);

  return GooglePlacesAutocomplete;
})(angular, google);
GooglePlacesAutocomplete = undefined;
