(function() {
  var app = angular.module('priorWeb');

  app.factory('RestaurantViewModel', [
    'apiEndpoint',
    function(apiEndpoint) {
      function RestaurantViewModel(restaurant) {
        var id = restaurant.getId(),
            name = restaurant.getName(),
            description = restaurant.getDescription(),
            deliveryCharge = restaurant.getDeliveryCharge(),
            address = restaurant.getAddress(),
            logo = restaurant.getLogo();

        this.getId = function() {
          return id;
        };

        this.getName = function() {
          return name;
        };

        this.getLogo = function() {
          return logo ? apiEndpoint + '/' + logo : undefined;
        };

        this.getDescription = function() {
          return description;
        };

        this.getExtendedDescription = function() {
          return description;
        };

        this.getAddress = function() {
          return address;
        };

        this.getDeliveryCharge = function() {
          return deliveryCharge;
        };

        this.getDistance = function() {
          var distance = restaurant.getDistance();
          if (Number.isFinite(distance)) {
            return ' a ' + (distance < 10000 ? roundDistance(distance) : 'más de 10km') +
              (restaurant.getAddress() ? ', en ' : '');
          } else {
            return '';
          }
        };

        this.getOpeningHours = function() {
          var schedules = restaurant.getSchedules().filter(function(schedule) {
            return schedule.getNumericDay() === ((new Date()).getDay() + 6) % 7;
          });
          if (schedules.length === 1) {
            return schedules[0].getOpeningHours();
          } else {
            return null;
          }
        };

        this.getOrderingAllowedHours = function() {
          var schedules = restaurant.getSchedules().filter(function(schedule) {
            return schedule.getNumericDay() === ((new Date()).getDay() + 6) % 7;
          });

          if (schedules.length === 1) {
            return schedules[0].getOrderingAllowed();
          } else {
            return null;
          }
        }

        function roundDistance(distance) {
          distance = Math.ceil(distance / 100) * 100;
          return (
            distance < 1000 ?
              distance + 'm' :
              Math.round(distance * 100) / 100000 + 'km'
          );
        }
      }

      RestaurantViewModel.build = function(restaurant) {
        return new RestaurantViewModel(restaurant);
      };

      return RestaurantViewModel;
    }
  ]);
})();
