(function() {
  var app = angular.module('priorWeb');

  app.factory('$cordovaGeolocation', [
    function() {
      function $cordovaGeolocation() {
      }

      return new $cordovaGeolocation();
    }
  ]);
})();
