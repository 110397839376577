var dataLayer;
var GoogleTagManager = (
  /**
   * @param {angular} angular
   * @param {array} dataLayer
   */
  function(angular, dataLayer) {
    /**
     * @param {ng.IDocumentService} $document
     * @param {string[]} gtmContainerIds
     */
    function GoogleTagManager($document, gtmContainerIds) {
      /** @type {string} */
      var userId;

      gtmContainerIds.forEach(/** @param {string} containerId */ function initContainer(containerId) {
        dataLayer.push({
          'gtm.start': new Date().getTime(),
          event:'gtm.js'
        });

        var firstTag = $document[0].getElementsByTagName('script')[0],
            newTag = $document[0].createElement('script');
        newTag.async = true;
        newTag.src = 'https://www.googletagmanager.com/gtm.js?id=' + containerId;
        firstTag.parentNode.insertBefore(newTag, firstTag);
      });

      this.getUserId = function gtmgetUserId() {
        return userId;
      };

      this.setUserId = /** @param {string} newUserId */ function gtmsetUserId(newUserId) {
        userId = newUserId;
      };
    }

    GoogleTagManager.prototype.trackGAEvent =
      /**
       * @param {string} propertyId
       * @param {string} category
       * @param {string} action
       * @param {string} [label='']
       * @param {number} [value=0]
       */
      function gtmTrackEvent(propertyId, category, action, label, value) {
        this.triggerMessage({
          'event': 'PriorEvent',
          'trackerId': propertyId,
          'event_category': category,
          'event_action': action,
          'event_label': label,
          'event_value': value
        });
      };

    GoogleTagManager.prototype.trackGATransaction =
      /**
       * @param {string} propertyId
       * @param {string} transactionId
       * @param {string} affiliation
       * @param {number} total
       * @param {string} currency
       * @param {string} shipping
       * @param {string} tax
       */
      function gtmTrackTransaction(propertyId, transactionId, affiliation, total, currency, shipping, tax) {
        this.triggerMessage({
          'event': 'PriorTransaction',
          'trackerId': propertyId,
          'transactionId': transactionId,
          'transactionAffiliation': affiliation,
          'transactionCurrency': currency,
          'transactionTotal': total,
          'transactionShipping': shipping,
          'transactionTax': tax
        });
      };

    GoogleTagManager.prototype.trackGAView =
      /**
       * @param {string} propertyId
       * @param {string} page
       */
      function gtmTrackGAView(propertyId, page) {
        this.triggerMessage({
          'attributes': {
            'page': page
          },
          'event': 'PriorPageView',
          'trackerId': propertyId
        });
      };

    /**
     * @param {object} data
     */
    GoogleTagManager.prototype.triggerMessage = function(data) {
      data.userId = this.getUserId();

      dataLayer.push(data);
    };

    var app = angular.module('priorWeb');
    app.factory('GoogleTagManager', [
      '$document', 'gtmContainerIds',
      /**
       * @param {ng.IDocumentService} $document
       * @param {string[]} gtmContainerIds
       */
      function GoogleTagManagerFactory($document, gtmContainerIds) {
        return new GoogleTagManager($document, gtmContainerIds);
      }
    ]);

    return GoogleTagManager;
  }
)(angular, dataLayer);
GoogleTagManager = undefined;
