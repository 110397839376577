(function() {
  var app = angular.module('priorWeb');

  app.factory('NonStandaloneMenuItemViewModel', [
    'MenuItemViewModel',
    function(MenuItemViewModel) {
      function NonStandaloneMenuItemViewModel(menuItem) {
        MenuItemViewModel.apply(this, [menuItem]);

        this.getView = function() {
          return 'templates/non-standalone-menu-item.html';
        };
      }

      NonStandaloneMenuItemViewModel.prototype = Object.create(MenuItemViewModel.prototype, {
        constructor: {
          value: NonStandaloneMenuItemViewModel,
          enumerable: false
        }
      });

      NonStandaloneMenuItemViewModel.build = function(menuItem) {
        return new NonStandaloneMenuItemViewModel(menuItem);
      };

      return NonStandaloneMenuItemViewModel;
    }
  ]);
})();
