/** @type {import("redux")} */
var Redux;
var State = (function() {
  var app = angular.module('priorWeb');

  /**
   * @param {import("ngstorage").ngStorage.StorageService} $localStorage
   * @param {ng.IWindowService} $window
   * @param {AppReducer} appReducer
   * @param {CurrentOrderReducer} currentOrderReducer
   * @param {ItemsReducer} itemsReducer
   */
  function State($localStorage, $window, appReducer, currentOrderReducer, itemsReducer) {
    var initialState = $localStorage.state ? $localStorage.state : undefined,
        reducers = {
          app: appReducer,
          currentOrder: currentOrderReducer,
          items: itemsReducer
        },
        store = Redux.createStore(
          Redux.combineReducers(reducers),
          initialState,
          $window.__REDUX_DEVTOOLS_EXTENSION__ && $window.__REDUX_DEVTOOLS_EXTENSION__()
        );

    /** @returns {import("redux").Store} */
    this.getStore = function getStore() {
      return store;
    };
  }

  /** @param {import("redux").AnyAction} action */
  State.prototype.dispatch = function dispatch(action) {
    this.getStore().dispatch(action);
  };

  State.prototype.get = function get() {
    return this.getStore().getState();
  };

  /** @param {() => void} listener */
  State.prototype.subscribe = function subscribe(listener) {
    this.getStore().subscribe(listener);
  };

  app.factory('State', [
    '$localStorage', '$window', 'AppReducer', 'CurrentOrderReducer', 'ItemsReducer',
    /**
     * @param {import("ngstorage").ngStorage.StorageService} $localStorage
     * @param {ng.IWindowService} $window
     * @param {AppReducer} appReducer
     * @param {CurrentOrderReducer} currentOrderReducer
     * @param {ItemsReducer} itemsReducer
     */
    function StateFactory($localStorage, $window, appReducer, currentOrderReducer, itemsReducer) {
      return new State($localStorage, $window, appReducer, currentOrderReducer, itemsReducer);
    }
  ]);

  return State;
})();
State = undefined;
