(function() {
  angular
    .module('uib/template/accordion/accordion-group.html', [])
    .run([
      '$templateCache',
      function($templateCache) {
        $templateCache.put(
          'uib/template/accordion/accordion-group.html',
          '<div class="panel" ng-class="panelClass || \'panel-default\'">\n' +
          '  <div role="tab" id="{{::headingId}}" aria-selected="{{isOpen}}" class="panel-heading" ng-keypress="toggleOpen($event)">\n' +
          '    <h4 class="panel-title">\n' +
          '      <a role="button" data-toggle="collapse" href aria-expanded="{{isOpen}}" aria-controls="{{::panelId}}" tabindex="0" class="accordion-toggle" ng-click="toggleOpen()" uib-accordion-transclude="heading"><span uib-accordion-header ng-class="{\'text-muted\': isDisabled}">{{heading}}</span></a>\n' +
          '    </h4>\n' +
          '  </div>\n' +
          '  <div id="{{::panelId}}" aria-labelledby="{{::headingId}}" aria-hidden="{{!isOpen}}" role="tabpanel" class="panel-collapse collapse" uib-collapse="!isOpen">\n' +
          '    <div class="panel-body" ng-transclude></div>\n' +
          '  </div>\n' +
          '</div>'
        );
      }]);
})();
