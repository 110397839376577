(function() {
  angular
    .module('priorWeb')
    .component('paymentModule', {
      templateUrl: 'templates/payment-module.html',
      controller: 'PaymentModuleController',
      bindings: {
        cardNumber: '<',
        addCard: '&',
        deleteCard: '&',
        paymentTypeUpdated: '&'
      }
    });
})();
