var ActionEnum = (function() {
  var app = angular.module('priorWeb');

  /** @enum {string} */
  var ActionEnum = {
    ADD_ORDER_ITEM: 'saveOrderItem',
    CHANGE_ORDER_ITEM_QUANTITY: 'changeItemQuantity',
    CLEAR_3DS_AUTHORIZATION: 'clear3dsAuthorization',
    CLEAR_ORDER: 'clearOrder',
    CLEAR_RESTAURANT_ID: 'clearRestaurantId',
    REMOVE_ORDER_ITEM: 'removeOrderItem',
    REMOVE_CUSTOMIZED_ITEM: 'removeCustomizedItem',
    REMOVE_SPECIFIC_ITEM: 'removeSpecificItem',
    REQUEST_3DS_AUTHORIZATION: 'request3dsAuthorization',
    SAVE_CUSTOMIZED_ITEM: 'saveCustomizedItem',
    SAVE_SPECIFIC_ITEM: 'saveSpecificItem',
    SET_COMMENTS: 'setComments',
    SET_DELIVERY_ADDRESS: 'setDeliveryAddress',
    SET_ORDER_ID: 'setOrderId',
    SET_PAYMENT_TYPE: 'setPaymentType',
    SET_PHONE: 'setPhone',
    SET_PROMO_CODE: 'setPromoCode',
    SET_RESTAURANT_ID: 'setRestaurantId',
    SET_STATUS: 'setStatus',
    SET_WHEN: 'setWhen',
    SET_WHERE: 'setWhere',
    SET_ZIP_CODE: 'setZipCode'
  };

  app.constant('ActionEnum', ActionEnum);

  return ActionEnum;
})();
ActionEnum = undefined;
