(function() {
  var app = angular.module('priorWeb');

  app.factory('SpecificMenuItemViewModel', [
    'MenuItemViewModel',
    function(MenuItemViewModel) {
      function SpecificMenuItemViewModel(menuItem) {
        MenuItemViewModel.apply(this, [menuItem]);

        this.getView = function() {
          return 'templates/menu-item.html';
        };
      }

      SpecificMenuItemViewModel.prototype = Object.create(MenuItemViewModel.prototype, {
        constructor: {
          value: SpecificMenuItemViewModel,
          enumerable: false
        }
      });

      SpecificMenuItemViewModel.build = function(menuItem) {
        return new SpecificMenuItemViewModel(menuItem);
      };

      return SpecificMenuItemViewModel;
    }
  ]);
})();
