var ga;
var CordovaGoogleAnalytics = (function() {
  var app = angular.module('priorWeb');

  /**
   * @constructor
   */
  function CordovaGoogleAnalytics() {
    /**
     * @param {string} id
     */
    this.startTrackerWithId = function startTrackerWithId(id) {
      ga('create', id, 'auto');
      ga('require', 'ecommerce');
    };

    /**
     * @param {string} userId
     */
    this.setUserId = function setUserId(userId) {
      ga('set', 'userId', userId);
    };

    this.trackEvent = function trackEvent(category, action, label, value) {
      ga('send', 'event', category, action, label, value);
    };

    this.trackView = function trackView(page, fieldsObject) {
      ga('send', 'pageview', page, fieldsObject);
    };

    this.addTransaction = function addTransaction(id, affiliation, revenue, shipping, tax, currency, autoSend) {
      autoSend = angular.isUndefined(autoSend) ? true : autoSend;
      ga('ecommerce:addTransaction', {
        'id': id,
        'affiliation': affiliation,
        'revenue': revenue,
        'shipping': shipping,
        'tax': tax,
        'currency': currency
      });
      if (autoSend) {
        ga('ecommerce:send');
      }
    };
  }

  app.factory('$cordovaGoogleAnalytics', [
    function() {
      return new CordovaGoogleAnalytics();
    }
  ]);

  return CordovaGoogleAnalytics;
})();
CordovaGoogleAnalytics = undefined;
